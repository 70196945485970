<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Création d'un ordre de mission</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="mx-lg-3">
                Ceci est une création d’ordre de mission direct, sans demande de devis au préalable
            </div>
        </div>
        <div class="uk-padding-small">
            <div class="m-3">
                <label class="form-label">Montant HT pré-validé pour l'ordre de mission</label>
                <input
                    step=".01"
                    class="uk-input"
                    type="number"
                    required
                    v-model="missionOrderPayload.preValidatedAmount"
                />
            </div>
            <div class="m-3">
                <label class="form-label">Lieux</label>
                <multiselect
                    v-model="missionOrderPayload.property"
                    :loading="isLoading"
                    :options="propertyListFiltered"
                    deselectLabel="Cliquez pour désélectionner"
                    label="name"
                    placeholder="taper un texte pour filtrer les lieux"
                    selectLabel="Cliquez pour sélectionner"
                    track-by="name"
                >
                    <template slot="noOptions">Aucun résultats</template>
                    <template slot="noResult">Aucun élément trouvé</template>
                </multiselect>
            </div>
            <div class="m-3">
                <label class="form-label">Objet de l'ordre de mission</label>
                <textarea
                    class="form-control rounded-0"
                    rows="15"
                    placeholder="Expliquez la problématique"
                    v-model="missionOrderPayload.mainPurpose"
                ></textarea>
            </div>
            <div class="m-3">
                <label class="form-label">Document</label>
                <FileUpload :files-uploaded.sync="missionOrderPayload.documents" />
            </div>
            <InformedWorkersList @selected:workers="updateMissionOrderWorker" />
            <div class="text-end mt-5">
                <button :disabled="recordPending" class="btn btn-primary" @click="create">
                    Créer
                    <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";
import InformedWorkersList from "@/components/InformedWorkersList.vue";
import {mapActions, mapState} from "vuex";
import store from "@/store";

export default {
    name: "MissionOrderCreate",
    components: {InformedWorkersList, FileUpload},
    data: () => ({
        propertyFilterValue: '',
        missionOrderPayload: {
            preValidatedAmount: 0,
            property: [],
            mainPurpose: '',
            documents: [],
            worker: [],
            siteUrl: window.location.protocol + '//' + window.location.host,
        },
    }),
    computed: {
        ...mapState('properties', { isLoading: 'isLoading', propertyList: 'list' }),
        ...mapState('workers', { workersList: 'list' }),
        ...mapState('missionOrders', ['recordPending']),
        propertyListFiltered() {
            const address = this.propertyList.map((item) => {
                return {
                    name: item.address.formattedAddress + ' - ' + item.name,
                    id: item.id,
                }
            })
            return address.filter((item) => item.id)
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('missionOrders', ['createMissionOrder']),
        create() {
            if (this.missionOrderPayload.preValidatedAmount <= 0) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez ajouter le montant pré-validé',
                })
                return
            }

            if (this.missionOrderPayload.property.length === 0) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez sélectionner un lieu',
                })
                return
            }

            if (!this.missionOrderPayload.mainPurpose) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez saisir un objet',
                })
                return
            }

            if (!this.missionOrderPayload.documents[0]) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez ajouter au moins un document',
                })
                return
            }

            if (!this.missionOrderPayload.worker[0]) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez sélectionner une entreprise',
                })
                return
            }

            if (this.missionOrderPayload.worker.length > 1) {
                this.addNotification({
                    status: 'warning',
                    message: 'Une seule entreprise/prestataire sera missionnée pour cet ordre de mission.',
                })
                return
            }

            const payload = {
                preValidatedAmount: this.missionOrderPayload.preValidatedAmount,
                property: this.missionOrderPayload.property,
                mainPurpose: this.missionOrderPayload.mainPurpose,
                documents: this.missionOrderPayload.documents,
                worker: this.missionOrderPayload.worker[0],
                siteUrl: this.missionOrderPayload.siteUrl
            }

            this.createMissionOrder(payload)
        },
        updateMissionOrderWorker(selectedWorkerIds) {
            let workers = []

            for (let i = 0; i < selectedWorkerIds.length; i++) {
                let worker = { id: selectedWorkerIds[i].id }
                workers.push(worker)
            }

            this.missionOrderPayload.worker = workers
        },
    },
    beforeRouteEnter(to, from, next) {
        Promise.all([store.dispatch('properties/updateProperties'), store.dispatch('workers/updateWorkers')])
            .then(() => {
                next()
            })
            .catch(() => {
                next(false)
            })
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
