<template>
    <div class="d-flex align-items-center">
        <div class="uk-margin-right">
            <multiselect
                v-model="selectedOptions.buildingTrades"
                :options="options.buildingTrades"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                track-by="id"
                label="value"
                :multiple="true"
                placeholder="Corps de métiers connus"
                @input="loadCompanies"
            >
                <template v-slot:noOptions> Aucun corps de métier disponible </template>
            </multiselect>
        </div>

        <div class="uk-margin-right">
            <div v-if="loading.companies" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <multiselect
                v-else
                :disabled="isCompaniesDisabled"
                v-model="selectedOptions.companies"
                :options="options.companies"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                track-by="id"
                label="name"
                :multiple="true"
                placeholder="Compagnies"
                @input="loadRegistrationNumbers"
            >
                <template v-slot:noOptions> Aucune compagnie disponible </template>
            </multiselect>
        </div>

        <div class="uk-margin-right">
            <div v-if="loading.registrationNumbers" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <multiselect
                v-else
                :disabled="isRegistrationNumbersDisabled"
                v-model="selectedOptions.registrationNumbers"
                :options="options.registrationNumbers"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                track-by="id"
                label="registrationNumber"
                :multiple="true"
                placeholder="SIREN"
            >
                <template v-slot:noOptions> Aucun numéro SIREN disponible </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'

export default {
    name: 'BuildingTradesFilter',
    props: {
        selected: {
            required: true,
            type: Object,
        },
    },
    data: () => ({
        options: {
            buildingTrades: [],
            companies: [],
            registrationNumbers: [],
        },
        selectedOptions: {
            buildingTrades: [],
            companies: [],
            registrationNumbers: [],
        },
        loading: {
            companies: false,
            registrationNumbers: false,
        },
        isCompaniesDisabled: true,
        isRegistrationNumbersDisabled: true,
    }),
    computed: {
        buildingTradesList() {
            return this.selectedOptions.buildingTrades.reduce((acc, item) => [...acc, item.id], [])
        },
        companiesList() {
            return this.selectedOptions.companies.reduce((acc, item) => [...acc, item.id], [])
        },
    },
    watch: {
        selected: {
            handler() {
                if (JSON.stringify(this.selected) !== JSON.stringify(this.selectedOptions)) {
                    this.selectedOptions = this.selected

                    if (this.selected.buildingTrades.length > 0) {
                        this.loadCompanies()
                    }

                    if (this.selected.companies.length > 0) {
                        this.loadRegistrationNumbers()
                    }
                }
            },
            immediate: true,
        },
        selectedOptions: {
            handler() {
                this.loadItems()
            },
            deep: true,
        },
    },
    methods: {
        loadBuildingTrades() {
            backendApi.getBuildingTradesList(store.state.login.user.token).then((response) => {
                this.options.buildingTrades = response
            })
        },
        loadCompanies() {
            if (this.selectedOptions.buildingTrades.length > 0) {
                this.isCompaniesDisabled = false
                this.loading.companies = true
                backendApi
                    .getCompaniesByBuildingTrades(this.buildingTradesList)
                    .then((response) => {
                        this.loading.companies = false
                        let arrayCompanies = []
                        Object.entries(response).forEach((item) => {
                            arrayCompanies.push(item[1].company)
                        })
                        this.options.companies = arrayCompanies
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading.companies = false
                        this.loading.registrationNumbers = false
                    })
            } else {
                this.isCompaniesDisabled = true
                this.selectedOptions.companies = []
                this.selectedOptions.registrationNumbers = []
            }
        },
        loadRegistrationNumbers() {
            if (this.selectedOptions.companies.length > 0) {
                this.isRegistrationNumbersDisabled = false
                this.loading.registrationNumbers = true
                backendApi
                    .getRegistrationNumbersByCompanies(this.companiesList)
                    .then((response) => {
                        this.loading.registrationNumbers = false
                        this.options.registrationNumbers = response
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading.registrationNumbers = false
                    })
            } else {
                this.isRegistrationNumbersDisabled = true
                this.selectedOptions.registrationNumbers = []
            }
        },
        loadItems() {
            this.$emit('change', JSON.parse(JSON.stringify(this.selectedOptions)))
        },
    },
    mounted() {
        this.loadBuildingTrades()
    },
}
</script>

<style>
.vgt-global-search__input {
    display: none !important;
}
.multiselect--disabled .multiselect__select {
    background: #fff !important;
    color: #fff !important;
}
</style>
